import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HomePageBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  background-image: url("/images/header/Carousel_04.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
`;

const HomePageBackgroundHighlighter = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  background: white;
`;

const InfoWrapper = styled.div`
  background: rgb(250 166 82 / 50%);
  width: 80vw;
  box-shadow: rgb(255 255 255 / 23%) 5px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 5% 2%;
  z-index: 9999;

  @media only screen and (min-width: 728px) {
    width: 40vw;
  }
`;

const LogoWrapper = styled.div`
  width: 66px;
  height: 88px;
  background: 0% 0% no-repeat padding-box padding-box rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  border-radius: 0px 0px 7px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrackGanpatiVisarjanLink = () => {
  const [show, setShow] = useState(false);

  const handleClose = (e) => setShow(false);
  const handleShow = (e) => setShow(true);

  return (
    <HomePageBackground>
      <HomePageBackgroundHighlighter />
      <InfoWrapper>
        <div style={{ color: "#fff", fontWeight: "bold" }}>
          <LogoWrapper>
            <img
              alt=""
              src="/images/logo.png"
              width="40"
              height="52"
              className="d-inline-block align-top"
            />
          </LogoWrapper>
          <p style={{ paddingTop: "5%" }}>{"Namaskar, Pune!"}</p>
          <p>
            {"गणपती बाप्पा मोरया, मंगलमूर्ती मोर्या !"}
            <br />
            {"आपण सर्वच ज्या उत्सवाची आतुरतेने वाट बघताय तो म्हणजे गणेशोत्सव."}
          </p>
          <p>
            {
              "This year, along with updating Visarjan Routes, Closed Roads, Live Diversions, Parking Locations and Head/Tail Live Position of Processions on the last day, we are going a step ahead and adding Pune Police Help-Desks and Walk-Only Suggested Routes for the benefit of Punekars."
            }
          </p>
          <p>
            {"On the Visarjan Day, Status of these 5 mirvanuks will be shared:"}
          </p>
          <p>
            {
              "Main Mirvanuk (City Area), Khadki, Hadapsar, Parvati Darshan and Yerwada."
            }
          </p>
        </div>
        <Link
          to={"/track-ganeshotsav"}
          // to={"/"}
          onClick={handleShow}
          style={{
            background: "white",
            color: "black",
            fontSize: "20px",
            padding: "8px 12px",
            border: "2px solid rgb(0, 0, 0)",
            borderRadius: "10px",
            fontWeight: "bold",
          }}
        >
          {"Track Ganeshotsav"}
        </Link>
      </InfoWrapper>
      <Modal show={show} animation={false} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Please note:"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {"Ganeshotsav tracking will start from 8th September 2024 onward."}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </HomePageBackground>
  );
};

function Home() {
  return (
    <>
      <TrackGanpatiVisarjanLink />
    </>
  );
}

export default Home;
