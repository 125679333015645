import React from "react";
import { keys } from "lodash";
import { mapperForRoute, mapperForRouteWithIcon, mapperForRouteColor } from "./SearchPoliceStation";

export const LegendForMap = ({ iconSize = 10, isChecked }) => (
  <div style={{ background: "#ffffffa3", padding: 10 }}>
    {keys(mapperForRoute)?.map((x) => (
      <div
        key={x}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "bold",
          marginBottom: 5,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", margin: 0, fontSize: iconSize }}>
          <input type="checkbox" id={x} checked={isChecked[x]} onChange={console.log} style={{ marginRight: 5 }} />
          <label htmlFor={x} style={{ margin: 0, cursor: "pointer" }}>{mapperForRoute[x]}</label>
        </div>
        <div
          style={{
            background: mapperForRouteColor[x],
            width: iconSize * 2,
            height: !mapperForRouteWithIcon[x] && (iconSize * 2),
            marginLeft: 10,
          }}
        >
          {
            mapperForRouteWithIcon[x] && <img
              alt="legend timestamp"
              src={mapperForRouteWithIcon[x]}
              style={{
                width: "100%",
                height: "100%",
                marginTop: "-10px",
              }}
            />
          }
        </div>
      </div>
    ))}
  </div>
);
