import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useGetLocalText } from "../../utils";
import ContactUs from "./ContactUs";
import navLinks from "./navLinks";
import { isUndefined } from "lodash";

const RenderNavLink = ({ data, children }) => {
  const { title } = useGetLocalText(data, ["title"]);

  return (
    <NavDropdown title={title} className="my-auto">
      {children}
    </NavDropdown>
  );
};

const RenderSubNavLink = ({ data, onSelect }) => {
  const { title } = useGetLocalText(data, ["title"]);

  const linkProps = data.href
    ? {
        as: "a",
        href: data.href,
        target: "_blank",
      }
    : {
        as: Link,
        to: data.link,
      };

  return (
    <NavDropdown.Item onClick={onSelect} {...linkProps}>
      {title}
    </NavDropdown.Item>
  );
};

const RenderLink = ({ data, onSelect }) => {
  const { title } = useGetLocalText(data, ["title"]);

  const linkProps = data.href
    ? {
        as: "a",
        href: data.href,
        target: "_blank",
      }
    : {
        as: Link,
        to: data.link,
      };

  return (
    <div
      style={{
        padding: "0.5rem",
        marginTop: "auto",
        marginBottom: "auto",
      }}
    >
      <Nav.Item
        onClick={onSelect}
        {...linkProps}
        style={{ color: "rgba(0,0,0,.5)" }}
      >
        {title}
      </Nav.Item>
    </div>
  );
};

const NavBar = ({ onSelect }) => {
  return (
    <>
      <Nav className="mr-n3 flex-fill justify-content-end" activeKey="/home">
        {navLinks?.map((link, index) =>
          isUndefined(link.href || link.link) ? (
            <RenderNavLink key={index} data={link}>
              {link.subLinks?.map((sublink, index2) => (
                <RenderSubNavLink
                  key={index2}
                  data={sublink}
                  onSelect={onSelect}
                />
              ))}
            </RenderNavLink>
          ) : (
            <RenderLink key={index} data={link} />
          )
        )}
        <Nav.Item>
          <ContactUs />
        </Nav.Item>
      </Nav>
    </>
  );
};

export default NavBar;
