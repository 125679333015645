import React from "react";
import { map } from "lodash";
import { Card } from "react-bootstrap";
import Spinner from "../../common/component/Loader";
import useGetResponse from "../../apis/useGetResponse";

export const RightSection = () => {
  const { data, isFetching, isError } = useGetResponse("getIcons");

  if (isFetching || isError || !data) {
    return (
      <div id="search-SearchBoxInput-station-left">
        <Spinner />;
      </div>
    );
  }

  return (
    <div id="search-SearchBoxInput-station-right" className="shadow">
      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            background: "rgb(250, 166, 82)",
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.5rem",
            border: "none",
          }}
        >
          Updates History
        </Card.Header>
        <Card.Body>
          {map(data.data, (x) => (
            <Card className="shadow" key={x} style={{ marginBottom: 10 }}>
              <Card.Header
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!!x.day && (
                  <Card.Title style={{ margin: 0 }}>{x.day}</Card.Title>
                )}
              </Card.Header>
              <Card.Body>
                {!!x.popup_text && <Card.Text>{x.popup_text}</Card.Text>}
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};
