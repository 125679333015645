import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import Dashboard from "./layout/dashboard";

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}

const ScrollToTopWithRouter = withRouter(ScrollToTop);

function App() {
  return (
    <Router>
      <React.Fragment>
        <ScrollToTopWithRouter />
        <Switch>
          <Route path="/" component={Dashboard} />
        </Switch>
      </React.Fragment>
    </Router>
  );
}

export default App;
