import React from "react";
import { Row, Navbar } from "react-bootstrap";
import FontResizer from "./FontResizer";
import LanguageSelector from "./LanguageSelector";
import Logo from "./Logo";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { useState } from "react";

export const PrimaryHeader = ({ title, hideHeaderOnPrint }) => (
  <>
    {title && (
      <div
        style={{ padding: "150px 0 30px", backgroundColor: "#FAA652" }}
        className={hideHeaderOnPrint ? "noprint" : ""}
      >
        <h1 style={{ textAlign: "center", color: "#fff" }}>{title}</h1>
      </div>
    )}
  </>
);

const Header = () => {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <header className="noprint">
      <PrimaryHeader />
      <div id="header-content-wrapper">
        <Row className={`justify-content-end m-2 mx-5-md mt-2-md other-page`}>
          <FontResizer />
          <LanguageSelector />
        </Row>
        <Row className="m-0 mx-md-5 mt-md-2">
          <Navbar
            onToggle={(expanded) => setNavExpanded(expanded)}
            expanded={navExpanded}
            expand="lg"
            className="primary-navbar rounded-pill"
          >
            <Navbar.Brand to="/" as={Link} className="ml-3">
              <Logo />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="primary-navbar" />
            <Navbar.Collapse id="primary-navbar" className="w-100">
              <NavBar onSelect={() => setNavExpanded(false)} />
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </div>
    </header>
  );
};

export default Header;
