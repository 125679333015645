import React from "react";
import { Container } from "react-bootstrap";
import translationText from "../../translation.js";
import Page from "../../common/component/Page";

const AboutUs = () => {
  return (
    <Page pageTitle={translationText.AboutUs}>
      <Container
        id="our-mission"
        style={{ fontSize: "1.5rem", padding: "30px" }}
      >
        <p>
          {
            "As the visarjan festivities start today, 8000+ Police Personnel will be deployed to ensure they conclude seamlessly."
          }
        </p>
        <p>
          {
            "Under our endeavour to ensure citizens are informed of the Ganpati Procession’s movement to help them plan their commute better, we will be updating "
          }
          <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {
              "the Latest Location of all major Ganpatis, Affected Roads, Parking Stretches, Closed Roads, Live Diversions, Expected Arrival Times at the next major landmark"
            }
          </span>
          {" and so on."}
        </p>
      </Container>
    </Page>
  );
};

export default AboutUs;
