import React, { useState } from "react";
import { Nav, Toast } from "react-bootstrap";
import { useRef, useEffect } from "react";
import { noop } from "lodash";
import RenderLocale from "../../common/component/RenderLocale";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onAlert = noop) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onAlert();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onAlert]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onAlert);

  return <div ref={wrapperRef}>{props.children}</div>;
}

const ContactUs = () => {
  const [show, setShow] = useState(false);
  return (
    <OutsideAlerter onAlert={() => setShow(false)}>
      <Nav.Link onClick={() => setShow(!show)}>
        <img
          alt=""
          src="/images/contact.svg"
          width="45"
          height="45"
          id="contact-navbar-option"
          className="d-inline-block align-top rounded-circle p-2"
        />
        <div
          aria-live="polite"
          aria-atomic="true"
          style={{
            position: "relative",
          }}
        >
          {show && (
            <Toast
              style={{
                position: "absolute",
                background: "#fff",
                boxShadow: "0px 0px 6px #00000029",
                top: 10,
                right: 0,
              }}
            >
              <Toast.Body
                style={{
                  width: 400,
                  maxWidth: "100%",
                  background: "#fff",
                  color: "rgba(0,0,0,.5)",
                }}
              >
                <div className="d-flex">
                  <p className="w-50" style={{ fontWeight: "bold" }}>
                    <RenderLocale keyName="PoliceControlRoomWithoutNo" />
                  </p>
                  <p className="w-50" style={{ textDecoration: "underline" }}>
                    <RenderLocale keyName="PoliceControlRoomNumber" />
                  </p>
                </div>
              </Toast.Body>
            </Toast>
          )}
        </div>
      </Nav.Link>
    </OutsideAlerter>
  );
};

export default ContactUs;
