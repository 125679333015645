import React from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { LegendForMap } from "./LegendForMap";
import { mapperForRoute } from "./SearchPoliceStation";
import { keys } from "lodash";

export const Legend = ({ onClick, isChecked }) => {
  const map = useMap();
  useEffect(() => {
    if (map) {
      const legend = L.control({ position: "bottomleft" });

      const htmlString = ReactDOMServer.renderToString(<LegendForMap isChecked={isChecked} />);

      legend.onAdd = () => {
        const div = L.DomUtil.create("div", "info legend");
        div.innerHTML = htmlString;
        return div;
      };

      legend.addTo(map);
      keys(mapperForRoute)?.map((x) =>
        document.getElementById(x).addEventListener('click', onClick)
      )

      return () => legend.remove(map)
    }
  }, [isChecked, map, onClick]); //here add map
  return null;
};
