import { filter, includes, keys } from "lodash";
import React from "react";
import useGetResponse from "../../apis/useGetResponse";
import Spinner from "../../common/component/Loader";
import { customIcons, MapWithMarker } from "./MapWithMarker";

export const MainSection = () => {
  const { data, isFetching, isError } = useGetResponse("getRoutes");
  const {
    data: iconData,
    isFetching: iconIsFetching,
    isError: iconIsError,
  } = useGetResponse("getIcons");

  let currentLocationLat = 18.5204;
  let currentLocationLng = 73.8567;
  navigator.geolocation.getCurrentPosition(function (position) {
    currentLocationLat = position.coords.latitude;
    currentLocationLng = position.coords.longitude;
  });

  if (
    isFetching ||
    iconIsFetching ||
    isError ||
    iconIsError ||
    !data ||
    !iconData
  ) {
    return (
      <div id="search-SearchBoxInput-station">
        <div className="search-SearchBoxInput-station-wrapper">
          <Spinner />;
        </div>
      </div>
    );
  }

  return (
    <div id="search-SearchBoxInput-station">
      <div className="search-SearchBoxInput-station-wrapper">
        <MapWithMarker
          data={data.data}
          lat={currentLocationLat}
          lng={currentLocationLng}
          iconOnMap={filter(iconData.data, (x) => includes(keys(customIcons), x.icon_type))}
        />
      </div>
    </div>
  );
};
