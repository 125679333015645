import translationText from "../../translation.js";

const navLinks = [
  {
    ...translationText.AboutUs,
    link: "/about-us"
  }
];

export default navLinks;
