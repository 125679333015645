import React from "react";
import Header from "../header/Header";
import Home from "../home/Home";
import Footer from "../footer/Footer";
import { QueryClient, QueryClientProvider } from "react-query";
import DashboardProvider from "./DashboardProvider";
import AboutUs from "../aboutUs";
import HowToUse from "../howToUse";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import GanpatiVisarjanLocationAndTraffic from "../ganpatiVisarjanLocationAndTraffic";
import { map } from "lodash";
import ScrollToTop from "react-scroll-to-top";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

export const routes = [
  { path: "/", component: Home },
  { path: "/about-us", component: AboutUs },
  {
    path: "/track-ganeshotsav",
    component: GanpatiVisarjanLocationAndTraffic,
  },
];

function Dashboard() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <DashboardProvider>
      <QueryClientProvider client={queryClient}>
        {!isHomePage && <Header />}
        <Switch>
          {map(routes, (route) => (
            <Route exact {...route} key={route.path} />
          ))}
          <Redirect from="/LostFoundReg" to="/report-us/lost-and-found" />
          <Redirect to="/" />
        </Switch>
        {!isHomePage && <Footer />}
      </QueryClientProvider>
      <ScrollToTop smooth color="#faa652" />
    </DashboardProvider>
  );
}

export default Dashboard;
