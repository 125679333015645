import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import RenderLocale from "../../common/component/RenderLocale";

const Footer = () => (
  <Container fluid className="noprint footer-flex">
    <Row>
      <Col className="p-0">
        <img
          alt=""
          src="/images/footer-image.png"
          height="176"
          id="footer-image"
          className="d-block align-top m-auto"
        />
      </Col>
    </Row>
    <Row id="footer-navbar" className="flex-column flex-md-row">
      <Col className="my-auto text-center p-2">
        <strong>
          <RenderLocale keyName="PoliceControlRoom" />
        </strong>
      </Col>
    </Row>
  </Container>
);

export default Footer;
