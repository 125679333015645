import React from "react";
import SearchPoliceStation from "../home/SearchPoliceStation";
import translationText from "../../translation.js";
import Page from "../../common/component/Page";

const GanpatiVisarjanLocationAndTraffic = () => (
  <Page pageTitle={translationText.GanpatiVisarjanLocationAndTraffic}>
    <SearchPoliceStation />
  </Page>
);

export default GanpatiVisarjanLocationAndTraffic;
