import React from "react";
import { map } from "lodash";
import { Badge, Card } from "react-bootstrap";
import { variants } from "./SearchPoliceStation";
import Spinner from "../../common/component/Loader";
import useGetResponse from "../../apis/useGetResponse";

export const LeftSection = () => {
  const { data, isFetching, isError } = useGetResponse("getImages");

  if (isFetching || isError || !data) {
    return (
      <div id="search-SearchBoxInput-station-left">
        <Spinner />;
      </div>
    );
  }

  return (
    <div id="search-SearchBoxInput-station-left" className="shadow">
      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            background: "rgb(250, 166, 82)",
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.5rem",
            border: "none",
          }}
        >
          Images From Ground
        </Card.Header>
        <Card.Body>
          {map(data.data, (x, index) => (
            <Card className="shadow" key={index} style={{ marginBottom: 10 }}>
              {!!x.url && (
                <Card.Img
                  style={{ maxHeight: "200px", marginRight: 10 }}
                  variant="top"
                  src={x.url}
                />
              )}
              <Card.Body>
                <Card.Title>
                  {!!x.title && <p style={{ marginBottom: 5 }}>{x.title}</p>}
                  {!!x.date && (
                    <p
                      style={{
                        marginBottom: 5,
                        fontSize: ".6em",
                        color: "grey",
                      }}
                    >
                      {x.date}
                    </p>
                  )}
                  {!!x.description && (
                    <Card.Text
                      style={{
                        fontSize: "0.8em",
                        fontWeight: "100",
                        marginBottom: 10,
                      }}
                    >
                      {x.description}
                    </Card.Text>
                  )}
                  {!!x.location && (
                    <Badge
                      variant={variants[index % 7]}
                      style={{ whiteSpace: "pre-wrap", textAlign: "left" }}
                    >
                      {x.location}
                    </Badge>
                  )}
                </Card.Title>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};
