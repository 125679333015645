import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from "react-leaflet";
import { Legend } from "./Legend";
import {
  ChangeView,
  getPath,
  mapperForRoute,
  mapperForRouteColor,
} from "./SearchPoliceStation";
import L from "leaflet";
import TimestampsPng from "../../images/clock.png";
import CarParkingSpotPng from "../../images/car.png";
import TwoWheelerParkingSpotPng from "../../images/2wheeler.png";
import PoliceSevaDeskPng from "../../images/helpdesk.png";
import MandalsLocationPng from "../../images/ganpatimandal.png";
import NewTabPng from "../../images/new-tab.png";
import { filter, keys } from "lodash";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";

const iconWithCustomIcon = icon => new L.Icon({
  iconUrl: icon,
  iconRetinaUrl: icon,
  iconAnchor: null,
  popupAnchor: [0, -8],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(30, 30),
  className: "",
});

export const customIcons = {
  timestamp: iconWithCustomIcon(TimestampsPng),
  punePoliceHelpDesk: iconWithCustomIcon(PoliceSevaDeskPng),
  ganpatiMandal: iconWithCustomIcon(MandalsLocationPng),
  carParkingSpot: iconWithCustomIcon(CarParkingSpotPng),
  twoWheelerParkingSpot: iconWithCustomIcon(TwoWheelerParkingSpotPng),
}

const getIconForMarker = ({ icon_type }) => customIcons[icon_type];

const filterData = (data) => {
  return filter(data, (x) => x.name !== "delete");
};

const CustomMarker = ({ data, isActive }) => {
  const leafletRef = useRef();

  useEffect(() => {
    if (isActive) {
      leafletRef.current.openPopup();
    }
  }, []);

  return (
    <Marker
      ref={leafletRef}
      position={[+data.lat, +data.lng]}
      icon={getIconForMarker(data)}
    >
      <Popup closeOnClick={false} closeOnEscapeKey={false} autoClose={false}>
        {data.popup_text}
        <span>
          <a href={`https://maps.google.com/?q=${data.lat},${data.lng}`} rel="noreferrer" target="_blank">
            <img alt="new tab" style={{ width: 15, marginLeft: 5 }} src={NewTabPng} />
          </a>
        </span>
      </Popup>
    </Marker>
  );
};

export const MapWithMarker = (props) => {
  const [isChecked, setIsChecked] = useState(
    keys(mapperForRoute)?.reduce((acc, value) => ({ ...acc, [value]: true }), {})
  )

  const iconOnMap = props.iconOnMap.filter(x =>
    (isChecked[x.icon_type])
  )
  const data = props.data.filter(x =>
    (isChecked[x.name])
  )
  return (
    <MapContainer
      center={[props.lat, props.lng]}
      zoom={14}
      scrollWheelZoom={false}
      className="map-container shadow"
    >
      <ChangeView center={[props.lat, props.lng]} zoom={14} />
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {filterData(data)?.map((d) => (
        <Polyline
          key={d.id}
          positions={getPath(d)}
          color={mapperForRouteColor[d.name]}
          weight={5}
        />
      ))}
      {iconOnMap?.map((data, index) => (
        <CustomMarker isActive={data.icon_type === "timestamp"} data={data} key={index} />
      ))}
      <Legend isChecked={isChecked} onClick={event => {
        setIsChecked({
          ...isChecked,
          [event.target.id]: event.target.checked
        })
      }} />
      <FullscreenControl />
    </MapContainer>
  )
};
