import React from "react";
import Section from "./Section";
import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet-defaulticon-compatibility";
import { MainSection } from "./MainSection";
import { RightSection } from "./RightSection";
import { LeftSection } from "./LeftSection";
import TimestampPng from "../../images/clock.png";
import CarParkingSpotPng from "../../images/car.png";
import TwoWheelerParkingSpotPng from "../../images/2wheeler.png";
import PoliceSevaDeskPng from "../../images/helpdesk.png";
import MandalsLocationPng from "../../images/ganpatimandal.png";

export const mapperForRoute = {
  punePoliceHelpDesk: "Pune Police Help Desk",
  ganpatiMandal: "Ganpati Mandal",
  carParkingSpot: "4 wheeler + 2 wheeler Parking",
  twoWheelerParkingSpot: "Only 2-wheeler Parking",
  timestamp: "Update",
  mirvanuk: "Mirvanuk Route",
};

export const mapperForRouteWithIcon = {
  punePoliceHelpDesk: PoliceSevaDeskPng,
  ganpatiMandal: MandalsLocationPng,
  carParkingSpot: CarParkingSpotPng,
  twoWheelerParkingSpot: TwoWheelerParkingSpotPng,
  timestamp: TimestampPng,
}

export const mapperForRouteColor = {
  Roadclosed: "#FF0000",
  mirvanuk: "#000000",
};

export const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

export const getPath = (d) => {
  try {
    var coordinates = JSON.parse(
      `[${d.coordinates
        .replaceAll("\r", "")
        .replaceAll("\n", "")
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("lat", '"lat"')
        .replaceAll("lng", '"lng"')}]`
    );

    return coordinates;
  } catch (error) {
    console.log("invalid data for:", d);
    return [];
  }
};

export const variants = [
  "primary",
  "secondary",
  "success",
  "danger",
  "warning",
  "info",
  "dark",
  "light",
];

const SearchBox = () => {
  return (
    <Section id="search-police-station" fluid>
      <div id="search-SearchBoxInput-station-wrapper">
        <LeftSection />
        <MainSection />
        <RightSection />
      </div>
      <div id="search-SearchBoxInput-station-wrapper-mobile">
        <MainSection />
        <RightSection />
        <LeftSection />
      </div>
    </Section>
  );
};

export default SearchBox;
