const translation = {
  EnterCaptchaHere: {
    title: "Enter Captcha Here",
    title_mr: "येथे कॅप्चा लिहा",
  },
  Submit: {
    title: "Submit",
    title_mr: "जमा करा",
  },
  AboutUs: {
    title: "About Ganpati Visarjan",
    title_mr: "गणपती विसर्जन बद्दल",
  },
  HowToUse: {
    title: "How To Use",
    title_mr: "कसे वापरावे",
  },
  OurMission: {
    title: "Our Mission",
    title_mr: "आमचे ध्येय",
  },
  SeniorPoliceOfficers: {
    title: "Senior Police Officers",
    title_mr: "वरिष्ठ पोलिस अधिकारी",
  },
  DivisionalACP: {
    title: "Divisional ACP's",
    title_mr: "विभागीय एसीपी",
  },
  OrganizationalStructure: {
    title: "Organizational Structure",
    title_mr: "संस्थात्मक रचना",
  },
  PuneCityPoliceMap: {
    title: "Pune City Police Map",
    title_mr: "पुणे शहर पोलिस नकाशा",
  },
  PuneCityPolice: {
    title: "Pune City Police",
    title_mr: "पुणे शहर पोलिस",
  },
  Initiatives: {
    title: "Initiatives",
    title_mr: "पुढाकार",
  },
  Gallery: {
    title: "Gallery",
    title_mr: "गॅलरी",
  },
  CitizenCorner: {
    title: "Citizen Corner",
    title_mr: "सिटीझन कॉर्नर",
  },
  SearchAndViewPublishedFIR: {
    title: "Published FIR",
    title_mr: "प्रकाशित प्रथम खबर",
  },
  AccidentCompensation: {
    title: "Accident Compensation",
    title_mr: "अपघात भरपाई",
  },
  RightToInformation: {
    title: "Right To Information",
    title_mr: "माहितीचा अधिकार",
  },
  RightToService: {
    title: "Right To Service",
    title_mr: "सेवेतील सेवा",
  },
  UsefulWebsites: {
    title: "Useful websites",
    title_mr: "उपयुक्त वेबसाइट",
  },
  ArmLicenseForms: {
    title: "Arm License Forms",
    title_mr: "आर्म लायसन्स फॉर्म",
  },
  CitizenAlertWall: {
    title: "Citizen Alert Wall",
    title_mr: "सिटीझन अलर्ट वॉल",
  },
  SafetyTips: {
    title: "Safety Tips",
    title_mr: "सेफ्टी टिप्स",
  },
  Tenders: {
    title: "Tenders",
    title_mr: "निविदा",
  },
  FAQ: {
    title: "FAQ",
    title_mr: "नेहमीचे प्रश्न",
  },
  PoliceCorner: {
    title: "Police Corner",
    title_mr: "पोलिस कॉर्नर",
  },
  InformationForPoliceOfficersMen: {
    title: "Information For Police Officers & Men",
    title_mr: "पोलिस अधिकार्‍यांसाठी माहिती आणि पुरुष",
  },
  MediaCoverage: {
    title: "Media Coverage",
    title_mr: "मीडिया कव्हरेज",
  },
  PressRelease: {
    title: "Press Release",
    title_mr: "प्रेस प्रकाशन",
  },
  CrimeReview: {
    title: "Crime Review",
    title_mr: "गुन्हे पुनरावलोकन",
  },
  PoliceRecruitment: {
    title: "Police Recruitment",
    title_mr: "पोलिस भरती",
  },
  GoodWork: {
    title: "Good Work",
    title_mr: "चांगले कार्य",
  },
  DarpanBook: {
    title: "Darpan Book",
    title_mr: "दर्पण बुक",
  },
  ReportUs: {
    title: "Report Us",
    title_mr: "आम्हाला कळवा",
  },
  OnlineComplaint: {
    title: "Online Complaint",
    title_mr: "ऑनलाईन तक्रार",
  },
  LostAndFound: {
    title: "Lost & Found",
    title_mr: "हरवले आणि सापडले",
  },
  LostAndFoundConfirm: {
    title:
      "I certify that the information provided by me in above form is accurate and correct as per best of my knowledge. I understand that withholding of information or giving false information is a criminal offense and may result in disciplinary legal action against me",
    title_mr:
      "मी प्रमाणित करतो की, माझ्या माहितीनुसार उपरोक्त अहवालामध्ये प्रदान केलेली माहिती अचूक आणि योग्य आहे. मी समजतो की, माहितीचे उल्लंघन करणे किंवा खोटी माहिती देणे हे एक फौजदारी गुन्हा आहे आणि परिणामी माझ्याविरुद्ध शिस्तभंगाची कायदेशीर कारवाई होऊ शकते.",
  },
  LostAndFoundDescription: {
    title:
      "This application is for providing Lost and Found Information to Police. Information reported under this application is not a subject matter of enquiry/investigation. False report to Police is a punishable offence. Reporting person shall be solely responsible for any false information. Dial 100, if there is any emergency.",
    title_mr:
      'हा अनुप्रयोग पोलिसांना "हरवलेले आणि सापडलेले" माहिती प्रदान करण्यासाठी आहे. या अर्जाच्या अंतर्गत अहवालात दिलेल्या माहितीची चौकशी / तपास करण्यात येत नाही. पोलिसांना खोटे माहिती देणे दंडनीय अपराध आहे. कोणत्याही चुकीच्या माहितीसाठी अहवाल देणारी व्यक्ती पूर्णपणे जबाबदार असेल. कोणत्याही आपत्कालीन परिस्थितीत १00 नंबर डायल करा.',
  },
  LostAndFoundDescription2: {
    title: (
      <>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            color: "#2A2D33",
            margin: 0,
          }}
        >
          {
            "In case of Lost Mobile Handset after registering complaint here please proceed to website"
          }
        </p>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            color: "#2A2D33",
          }}
        >
          <a
            style={{ color: "#D57E28", textDecoration: "underline" }}
            href="https://www.ceir.gov.in"
            target="_blank"
            rel="noreferrer"
          >
            {"here"}
          </a>
          {" for blocking the handset and all India traceability"}
        </p>
      </>
    ),
    title_mr: (
      <>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            color: "#2A2D33",
            margin: 0,
          }}
        >
          {
            "In case of Lost Mobile Handset after registering complaint here please proceed to website"
          }
        </p>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            color: "#2A2D33",
          }}
        >
          <a
            style={{ color: "#D57E28", textDecoration: "underline" }}
            href="https://www.ceir.gov.in"
            target="_blank"
            rel="noreferrer"
          >
            {"here"}
          </a>
          {" for blocking the handset and all India traceability"}
        </p>
      </>
    ),
  },
  TenantInformation: {
    title: "Tenant Information",
    title_mr: "भाडेकरू माहिती",
  },
  Feedback: {
    title: "Feedback",
    title_mr: "अभिप्राय",
  },
  ConfidentialInfo: {
    title: "Confidential Info",
    title_mr: "गोपनीय माहिती",
  },
  Branch: {
    title: "Branch",
    title_mr: "शाखा",
  },
  ControlRoom: {
    title: "Control Room",
    title_mr: "नियंत्रण कक्ष",
  },
  CrimeBranch: {
    title: "Crime Branch",
    title_mr: "गुन्हे शाखा",
  },
  SpecialBranch: {
    title: "Special Branch",
    title_mr: "विशेष शाखा",
  },
  PoliceHeadQuarter: {
    title: "Police Head Quarter",
    title_mr: "पोलिस हेड क्वार्टर",
  },
  TrafficBranch: {
    title: "Traffic Branch",
    title_mr: "रहदारी शाखा",
  },
  TopicNo: {
    title: "Topic No.",
    title_mr: "विषय क्र.",
  },
  Title: {
    title: "Title",
    title_mr: "शिर्षक",
  },
  Info: {
    title: "Info",
    title_mr: "माहिती",
  },
  AtrocityCases: {
    title: "Atrocity Cases",
    title_mr: "अट्रॉसिटी अंतर्गत दाखल गुन्हे ",
  },
  Atrocities: {
    title: "Cases registered under SC & ST (Prevention of Atrocity) Act 1989",
    title_mr:
      "अ.जा. व अ.ज. (अत्याचार प्रतिबंध) अधिनियम, १९८९ अंतर्गत दाखल गुन्हे",
  },
  DateOfUpload: {
    title: "Date Of Upload",
    title_mr: "प्रदर्शित दिनांक",
  },
  LastDateOfSubmission: {
    title: "Last Date Of Submission",
    title_mr: "जमा करण्याची अंतिम तारीख",
  },
  PublishDate: {
    title: "Publish Date",
    title_mr: "प्रसिद्धी दिनांक",
  },
  NewsTitle: {
    title: "News Title",
    title_mr: "बातमीचे शीर्षक",
  },
  MediaName: {
    title: "Media Name",
    title_mr: "वृत्त माध्यम",
  },
  News: {
    title: "News",
    title_mr: "बातमी",
  },
  Date: {
    title: "Date",
    title_mr: "तारीख",
  },
  PressNote: {
    title: "Press Note",
    title_mr: "प्रेस नोट",
  },
  NCRBStatisticalInformation: {
    title: "NCRB Statistical Information",
    title_mr: "राष्ट्रीय गुन्हे नोंद आकडेवारीची माहिती",
  },
  Externee: {
    title: "Externee",
    title_mr: "तडीपार",
  },
  RegisteredFIR: {
    title: "Registered FIR",
    title_mr: "प्रथम खबरी अहवाल",
  },
  DrunkDriveCases: {
    title: "Drunk Drive Cases",
    title_mr: "मद्यधुंद गाडी चालवणे अंतर्गत खटले",
  },
  CrimeStatistics: {
    title: "Crime Statistics",
    title_mr: "गुन्हे सांख्यिकी",
  },
  AbsconderList: {
    title: "Absconder List",
    title_mr: "फरार आरोपींची यादी",
  },
  InformationOfLllegalActivities: {
    title: "Information of illegal Activities",
    title_mr: "अवैध धंद्याची माहिती",
  },
  Reports: {
    title: "Reports",
    title_mr: "अहवाल",
  },
  Disclaimer: {
    title: "Disclaimer",
    title_mr: "अस्वीकरण",
  },
  WhatsApp: {
    title: "WhatsApp : 8975283100",
    title_mr: "व्हाट्सएप : ८९७५२८३१००",
  },
  WomenHelpline: {
    title: "Women Helpline : 1091",
    title_mr: "महिला हेल्पलाइन : १०९१",
  },
  WomenHelplineWithoutNo: {
    title: "Women Helpline :",
    title_mr: "महिला हेल्पलाइन :",
  },
  PoliceControlRoom: {
    title: "Control Room : 100/112",
    title_mr: "नियंत्रण कक्ष : १००/११२",
  },
  PoliceControlRoomWithoutNo: {
    title: "Control Room :",
    title_mr: "नियंत्रण कक्ष :",
  },
  ElderHelpLine: {
    title: "Elder Line : 1098",
    title_mr: "जेष्ठ नागरिक : १०९८",
  },
  ElderHelpLineWithoutNo: {
    title: "Elder Line :",
    title_mr: "जेष्ठ नागरिक :",
  },
  SiteMap: {
    title: "Site Map",
    title_mr: "साइट मॅप",
  },
  SeniorOfficerList: {
    title: "Senior Officer List",
    title_mr: "वरिष्ठ अधिकारी सूची",
  },
  InformationAndServices: {
    title: "Information & Services",
    title_mr: "माहिती आणि सेवा",
  },
  InformationOfIllegalActivities: {
    title: "Information of illegal Activities",
    title_mr: "अवैध धंद्याची माहिती",
  },
  UnidentifiedDeadBodies: {
    title: "Unidentified Dead Bodies",
    title_mr: "अनोळखी मृतदेह",
  },
  ExcavationNocApplication: {
    title: "Excavation NOC application",
    title_mr: "उत्खनन /खोदकाम ना हरकत परवाना",
  },
  QuickLinks: {
    title: "Quick Links",
    title_mr: "जलद दुवे",
  },
  SearchHere: {
    title: "Search Here...!",
    title_mr: "येथे शोधा...!",
  },
  MostUsedByPunekars: {
    title: "Most Used By Punekars",
    title_mr: "पुणेकरांनी सर्वाधिक वापरले",
  },
  MostUsedByPunekarsDescription: {
    title:
      "From 'Lost & Found' to 'Tenant Information' and everything in between, we have listed Quick Links of our most used sections here for you.",
    title_mr:
      "'लॉस्ट अँड फाउंड' पासून ते 'भाडेकरू माहिती' पर्यंत आणि त्यामधील प्रत्येक गोष्ट, आम्ही तुमच्यासाठी आमच्या सर्वाधिक वापरल्या जाणाऱ्या विभागांच्या जलद दुवे सूचीबद्ध केले आहेत.",
  },
  MissingPersons: {
    title: "Missing Persons",
    title_mr: "बेपत्ता व्यक्ती",
  },
  StolenAndUnclaimedVehicles: {
    title: "Stolen & Unclaimed Vehicles",
    title_mr: "चोरलेली आणि दावे न केलेली वाहने",
  },
  EngageWithCP: {
    title: "Engage with CP",
    title_mr: "आयुक्तांसोबत सहभागी व्हा",
  },
  EngageWithCPDescription: {
    title:
      "Welcome to this section! Whether you want to post your opinion or give constructive feedback, I would be glad to engage with you here in a structured manner.",
    title_mr:
      "या विभागात आपले स्वागत आहे! तुम्हाला तुमचे मत पोस्ट करायचे आहे किंवा विधायक अभिप्राय द्यायचा आहे, मला इथे तुमच्याशी रचनात्मक पद्धतीने सामील होण्यास आनंद होईल.",
  },
  MeetTheChief: {
    title: "Meet the Chief",
    title_mr: "भेट प्रमुखांची",
  },
  AmitabhGuptaIPS: {
    title: "Amitabh Gupta IPS, Commissioner of Police, Pune City",
    title_mr: "अमिताभ गुप्ता आयपीएस, पोलीस आयुक्त, पुणे शहर",
  },
  AmitabhGuptaIPSNote: {
    title:
      "An alumnus of IIT Kanpur, he is an IPS officer of 1992 batch with 29 years of service.",
    title_mr:
      "आयआयटी कानपूरचे माजी विद्यार्थी, २९ वर्षांच्या सेवेसह ते १९९२ च्या बॅचचे आयपीएस अधिकारी आहेत.",
  },
  AmitabhGuptaIPSNoteDetails: {
    title: (
      <>
        <p style={{ color: "#959595" }} className="pt-2">
          {"Namaskar, Punekars."}
        </p>
        <p style={{ color: "#959595" }}>
          {"Our city Pune, a major IT and education hub is home to "}
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"informed and educated"}
          </span>
          {
            " citizens who believe in actively participating in governance at all levels. I feel responsible to be heading Pune’s Police Force and want to ensure we enforce law of the land impartially, firmly and without fear or favor. We strive to create a "
          }
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"fear-free environment"}
          </span>
          {
            " that is conducive to growth and development of our beautiful city."
          }
        </p>
        <p style={{ color: "#959595" }}>
          {
            "Our mission is to prevent, deter and detect crime. To achieve this, we focus on these "
          }
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"4 broad areas"}
          </span>
          {
            ": Frontline Policing, Community Engagement, Comprehensive Investigations and Use of Technology."
          }
        </p>
        <p style={{ color: "#959595" }}>
          {"I want you to know that Pune Police is "}
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"always available in your service."}
          </span>
        </p>
      </>
    ),
    title_mr: (
      <>
        <p style={{ color: "#959595" }} className="pt-2">
          {"नमस्कार, पुणेकर."}
        </p>
        <p style={{ color: "#959595" }}>
          {"आपले पुणे शहर, एक प्रमुख आयटी आणि शैक्षणिक  केंद्र आहे जे अशा "}
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"सुजाण आणि सुशिक्षित"}
          </span>
          {
            " नागरिकांचे घर आहे ज्यांचा प्रशासनातील सर्व स्तरांवर सक्रिय सहभाग घेण्यावर विश्वास आहे. मी पुण्याच्या पोलीस दलाचे प्रमुख ह्या नात्याने जबाबदारी घेतो आणि हे सुनिश्चित करू इच्छितो की आम्ही जमिनीचा कायदा निष्पक्षपणे, दृढतापुर्वक आणि भीती किंवा पूर्वग्रह न ठेवता लागू करू. आम्ही आपल्या सुंदर शहराच्या वाढीसाठी आणि विकासासाठी एक "
          }
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"भयमुक्त वातावरण"}
          </span>
          {" निर्माण करण्याचा प्रयत्न करू."}
        </p>
        <p style={{ color: "#959595" }}>
          {
            "गुन्हे रोखणे, प्रतिबंध करणे आणि ते उघडकीस आणणे हे आमचे ध्येय आहे. ते साध्य करण्यासाठी, आम्ही या "
          }
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"चार विस्तृत क्षेत्रांवर"}
          </span>
          {
            " लक्ष केंद्रित करतो : अग्रगण्य पोलीस खाते  (फ्रंटलाईन पोलिसिंग), सामुदायिक प्रतिबद्धता (कम्युनिटी एंगेजमेंट), सर्वसमावेशक तपास आणि तंत्रज्ञानाचा वापर."
          }
        </p>
        <p style={{ color: "#959595" }}>
          {"माझी अशी इच्छा आहे कि पुणे पोलीस "}
          <span style={{ fontWeight: "bold", color: "#FAA652" }}>
            {"सदैव तुमच्या सेवेत हजर"}
          </span>
          {" आहेत ह्याची खात्री बाळगावी."}
        </p>
      </>
    ),
  },
  LatestFromPunePolice: {
    title: "Latest from Pune Police",
    title_mr: "पुणे पोलिसांकडून नवीनतम",
  },
  LatestFromPunePoliceDescription: {
    title:
      "We believe in clear & effective communication with citizens and hence rely on the Power of Social Media and News to do that seamlessly.",
    title_mr:
      "आमचा नागरिकांशी स्पष्ट आणि प्रभावी संप्रेषणावर विश्वास आहे आणि म्हणून ते अखंडपणे करण्यासाठी सोशल मीडिया आणि बातम्यांच्या सामर्थ्यावर अवलंबून आहे",
  },
  Logout: {
    title: "Logout",
    title_mr: "बाहेर पडणे",
  },
  Login: {
    title: "Log in",
    title_mr: "लॉगिन करा",
  },
  Example: {
    title: "Example",
    title_mr: "उदाहरण",
  },
  NewsUpdates: {
    title: "News Updates",
    title_mr: "बातम्या अद्यतने",
  },
  SpecialMentionDescription: {
    title:
      "In this section you will find inspiring stories of bravery and sacrifice to the Covid 19 work that our team did by going out of their line of duty.",
    title_mr:
      "या विभागात तुम्हाला कोविड 19 कामाच्या कार्यासाठी शौर्य आणि त्यागाच्या प्रेरणादायी कथा सापडतील जे आमच्या टीमने त्यांच्या कर्तव्याच्या बाहेर जाऊन केले.",
  },
  SpecialMention: {
    title: "Special Mention",
    title_mr: "विशेष उल्लेख",
  },
  OurInitiatives: {
    title: "Our Initiatives",
    title_mr: "आमचे पुढाकार",
  },
  OurInitiativesMore: {
    title:
      "We have been launching regular initiatives to solve certain pain point of citizens and thus improve the current situation.",
    title_mr:
      "आम्ही नागरिकांच्या विशिष्ट वेदनांच्या समस्येचे निराकरण करण्यासाठी आणि अशा प्रकारे सद्य परिस्थिती सुधारण्यासाठी नियमित उपक्रम सुरू करत आहोत.",
  },
  StoriesOfSacrificeAndBravery: {
    title: "Stories of Sacrifice & Bravery",
    title_mr: "त्याग आणि शौर्याच्या कथा",
  },
  StoriesOfSacrificeAndBraveryMore: {
    title:
      "We believe true stories of courage and selfless service can inspire all of us in more ways than one and hence are important to highlight",
    title_mr:
      "आमचा विश्वास आहे की धैर्य आणि निःस्वार्थ सेवेच्या सत्य कथा आपल्या सर्वांना एकापेक्षा अधिक मार्गांनी प्रेरित करू शकतात आणि म्हणूनच हायलाइट करणे महत्वाचे आहे",
  },
  ReadMore: {
    title: "Read More",
    title_mr: "पुढे वाचा",
  },
  SearchPoliceStation: {
    title: "Find Your Nearest Pune Police Chowky",
    title_mr: "तुमचा जवळचा पुणे पोलीस चौकी शोधा",
  },
  PunePoliceInPictures: {
    title: "Pune Police in Pictures",
    title_mr: "चित्रांमध्ये पुणे पोलीस",
  },
  PunePoliceInPicturesDescription: {
    title:
      "In the new world where most of us like to keep ourselves updated via pictures, this section takes you through the latest happenings pertaining to Pune Police!",
    title_mr:
      "नवीन जगात जिथे आपल्यापैकी बहुतेकांना स्वतःला चित्रांद्वारे अपडेट ठेवणे आवडते, हा विभाग तुम्हाला पुणे पोलिसांशी संबंधित नवीनतम घटनांमधून घेऊन जातो!",
  },
  WomenHelplineNumber: {
    title: "02026050191 , 1091",
    title_mr: "०२०२६०५०१९१ , १०९१",
  },
  WhatsAppHelpline: {
    title: "WhatsApp Helpline :",
    title_mr: "व्हाट्सऍप हेल्पलाइन :",
  },
  WhatsAppHelplineNumber: { title: "8975283100", title_mr: "८९७५२८३१००" },
  ElderHelpLineNumber: { title: "1098", title_mr: "१०९८" },
  PoliceControlRoomNumber: { title: "100/112", title_mr: "१००/११२" },
  PoliceExchange: {
    title: "Police Exchange :",
    title_mr: "पोलीस एक्सचेंज :",
  },
  PoliceExchangeNumber: { title: "02026122202", title_mr: "०२०२६१२२२०२" },
  CyberHelpline: { title: "Cyber helpline :", title_mr: "सायबर हेल्पलाईन :" },
  CyberHelplineNumber: {
    title: "02029710097, 7058719371, 7058719375",
    title_mr: "०२०२९७१००९७, ७०५८७१९३७१, ७०५८७१९३७५",
  },
  Search: {
    title: "Search Page",
    title_mr: "Search Page",
  },
  NoRecordFound: {
    title: "No Record Found",
    title_mr: "कोणतेही रेकॉर्ड सापडले नाही",
  },
  ListOfPoliceChowkies: {
    title: "List Of Police Chowkies",
    title_mr: "पोलीस चौकींची यादी",
  },
  SwargatePoliceStation: {
    title: "Swargate Police Station",
    title_mr: "स्वारगेट पोलीस स्टेशन",
  },
  NoSearchResult: {
    title:
      "No search results pertaining to your keywords. Please browse through the Quick Links section on the landing page , Header or Footer to find what you are looking for.",
    title_mr:
      "आपल्या कीवर्डशी संबंधित कोणतेही शोध परिणाम नाहीत. कृपया आपण जे शोधत आहात ते शोधण्यासाठी लँडिंग पृष्ठ, शीर्षलेख किंवा तळटीवर द्रुत दुवे विभाग ब्राउझ करा.",
  },
  ReportType: {
    title: "Report Type *",
    title_mr: "अहवाल प्रकार *",
  },
  PoliceStation: {
    title: "Police Station",
    title_mr: "पोलीस ठाणे",
  },
  ArticleType: {
    title: "Article type",
    title_mr: "दस्तऐवज",
  },
  DocumentIDNo: {
    title: "Document ID No.*",
    title_mr: "दस्तऐवजाचा क्रमांक*",
  },
  IMEINo: {
    title: "IMEI No",
    title_mr: "आय मी आय क्रमांक *",
  },
  LostFoundDateTime: {
    title: "Lost / Found Date & Time",
    title_mr: "हरवल्याची / सापडल्याची वेळ आणि तारीख",
  },
  FullName: {
    title: "Full Name",
    title_mr: "पूर्ण नाव",
  },
  EMailID: {
    title: "E-mail ID *",
    title_mr: "ई-मेल आयडी *",
  },
  ContactNumberOTP: {
    title: "Contact Number (for OTP) *",
    title_mr: "संपर्क क्रमांक (for OTP) *",
  },
  Address: {
    title: "Address *",
    title_mr: "पत्ता *",
  },
  PinCode: {
    title: "Pin code",
    title_mr: "पिनकोड",
  },
  ArticleDescription: {
    title: "Article Description *",
    title_mr: "लेख तपशील *",
  },
  MobileNumberLostMobileHandset: {
    title: "Mobile Number in use in case of Lost Mobile Handset*",
    title_mr: "गहाळ झालेल्या मोबाईलचा संपर्क क्रमांक*",
  },
  PlaceLostFoundOtherDetails: {
    title: "Place of Lost / Found and Other Details (If Any)",
    title_mr: "हरवलेले/सापडलेले स्थान व अन्य तपशील (जर असेल तर)",
  },
  VisitorCount: {
    title: "Visitor Count: ",
    title_mr: "संकेतस्थळ अभ्यागत: ",
  },
  PuneInspectorEOW: {
    title: "Sr. Police Inspector EOW : ",
    title_mr: "वरीष्ठ पोलीस निरीक्षक आर्थिक गुन्हे शाखा : ",
  },
  LastUpdatedOn: {
    title: "Last Updated On: ",
    title_mr: "अखेरचे अद्यतनित: ",
  },
  GanpatiVisarjanLocationAndTraffic: {
    title: (
      <>
        <div style={{ textTransform: "uppercase" }}>
          {"Ganeshotsav Tracking"}
        </div>
        <div style={{ fontSize: "0.3em", marginTop: 5 }}>
          {"Mirvanuk Route | Ganeshotsav Location | Parking Location | Closed Roads | Diversions | Latest Updates"}
        </div>
      </>
    ),
    title_mr: (
      <>
        <div>{"गणेशोत्सव ट्रॅकिंग"}</div>
        <div style={{ fontSize: "0.3em", marginTop: 5 }}>
          {"मिरवानुक मार्ग | गणेशोत्सव स्थळ | पार्किंग स्थान | बंद रस्ते | वळवणे | नवीनतम अद्यतने"}
        </div>
      </>
    ),
  }
};

export default translation;
